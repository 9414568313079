import React from "react";

import { useCallback } from "react";
import { useRef, useState } from "react";
import { useSnackbar } from 'contexts/SnackbarProvider';

export const ProctoringContext = React.createContext({
    moveAwayCount: 0,
    tabSwitchCount: 0,

    /**
     * 
     * @returns {void}
     */
    activateGeneralProctoring: () => { },
    activateFullScreenProctoring: () => { },

    /**
      * 
      * @param {number} captureRate
      * @param {MediaStream} stream
      * @param {HTMLVideoElement} videoElement
      * @returns {Promise<void>}
      */
    activateImageProctoring: async (captureRate, streamRef, videoElementRef) => { },

    /**
     * 
     * @returns {Promise<void>}
     */
    deactivateImageProctoring: () => { },

    /**
     * 
     * @returns {Promise<Array>} proctoringRecords
     */
    endProctoring: () => { },

    /**
     * 
     * @returns {Array} proctoringRecords
     */
    getProctoringData: () => { },


    /**
     * 
     *@returns {void}
     */
    enableFullscreenMode: () => { },


    /**
     * 
     *@returns {void}
     */
    disableFullscreenMode: () => { },
});


export default function ProctoringProvider({ children }) {

    const [moveAwayCount, setMoveAwayCount] = useState(0);
    const [tabSwitchCount, setTabSwitchCount] = useState(0);
    const [proctoringRecords, setProctoringRecords] = useState([]);
    const [generalProctoringEnabled, setGeneralProctoringEnabled] = useState(false);
    const snackbar = useSnackbar();

    const lastProctoringRecords = useRef([]);

    const handleBlur = useCallback((e) => {
        console.log('blur:: moving away');
        setMoveAwayCount((moveAwayCount) => {
            const updatedCount = moveAwayCount + 1;

            snackbar.showSnackbar('Stay in Window: Violation Detected!', "error");
            return updatedCount;
        });
    }, []);

    const handleVisibilityChange = useCallback((e) => {
        console.log('visibilityChange:: tab switch', window.document.visibilityState);
        if (window.document.visibilityState === 'visible') {
            setTabSwitchCount((tabSwitchCount) => {
                const updatedCount = tabSwitchCount + 1;

                snackbar.showSnackbar('No Tab Switching: Alert! Stay Focused!', "error");
                return updatedCount;
            });
        }
    }, []);

    const activateGeneralProctoring = useCallback(() => {
        if (generalProctoringEnabled) return;

        console.log('ProctoringProvider:: activateGeneralProctoring');
        setMoveAwayCount(0);
        setTabSwitchCount(0);
        window.addEventListener('blur', handleBlur);
        window.addEventListener('visibilitychange', handleVisibilityChange);
        setGeneralProctoringEnabled(true);
    }, [
        generalProctoringEnabled,
        handleBlur,
        handleVisibilityChange
    ]);

    const endProctoring = () => {
        console.log('ProctoringProvider:: endProctoring');

        lastProctoringRecords.current = [...proctoringRecords];

        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('visibilitychange', handleVisibilityChange);

        setGeneralProctoringEnabled(false);
        setProctoringRecords([]);
    };

    const getProctoringData = () => {
        console.log('ProctoringProvider:: getProctoringData');

        const generalProctoringRecords = [];
        if (generalProctoringEnabled) {
            generalProctoringRecords.push({
                type: 'GENERAL_PROCTOR',
                moveAwayCount,
                tabSwitchCount,
            });
        }

        return [...generalProctoringRecords, ...lastProctoringRecords.current];
    };

    return (
        <ProctoringContext.Provider value={
            {
                moveAwayCount,
                tabSwitchCount,
                activateGeneralProctoring,
                endProctoring,
                getProctoringData,
            }
        }>
            {children}
        </ProctoringContext.Provider>
    )
}