import Box from "@mui/material/Box";

export default function AppShell({ children, logo, logoPosition = "bottom", ...props }) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            backgroundColor="#202124"
            flexDirection='column'
            position="relative"
            {...props}
        >
            {children}
        </Box>
    );
}