import LogRocket from 'logrocket';

class SessionSingleton {
  userId = "";
  user = ""
  accessToken = "";
  accessTokenExpiry = Date.now();
  refreshToken = "";
  refreshTokenExpiry = Date.now();
  roles = [];

  _setUser(user) {
    this.userId = user._id;
    this.user = user;
    if (this.user?.client) {
      this.client = user?.client;
      this.clientId = user?.client._id;
    }
    this.roles = user?.roles || [];

    localStorage.setItem('guestId', this.userId);

    if (process.env.REACT_APP_STAGE === 'prod' &&
      process.env.NODE_ENV === 'production') {
      if (this.isLoggedIn()) {
        LogRocket.identify(this.userId, { registered: false });
      } else {
        LogRocket.identify(this.userId, {
          email: this.user?.email, phone: this.user?.phone, registered: true
        });
      }
    }
  }

  _unsetUser() {
    this.userId = "";
    this.user = null;
    this.clientId = "";
    this.client = null;
    this.roles = [];
  }

  getUser() {
    return this.user || {};
  }

  getUserMetadata() {
    return this.user?.metadata || {};
  }

  getClientMetadata() {
    return this.client?.metadata || this.user?.metadata || {};
  }

  onCreateSession({ user, tokens, guest = false,redirectUrl}) {
    this._setUser(user);
    this.redirectUrl = redirectUrl ||
     this.user?.metadata?.redirectUrl || 
     this.client?.metadata?.redirectUrl;
    this.accessToken = tokens.accessToken;
    this.accessTokenExpiry = new Date(tokens.accessExpireAt).getTime();
    this.refreshToken = tokens.refreshToken;
    this.refreshTokenExpiry = new Date(tokens.refreshExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", (!guest).toString());
    localStorage.setItem("languifyRefreshTokenExpiry", this.refreshTokenExpiry.toString());
    localStorage.setItem("languifyRefreshToken", this.refreshToken);
    localStorage.setItem("isGuestUser", guest.toString());
  }

  onRestoreSession({ accessToken, accessExpireAt, user, guest = false }) {
    this._setUser(user);
    this.accessToken = accessToken;
    this.accessTokenExpiry = new Date(accessExpireAt).getTime();
    localStorage.setItem("languifyIsLoggedIn", (!guest).toString());
  }

  clearSession() {
    try {
      this.accessToken = "";
      this.accessTokenExpiry = Date.now();
      this.refreshToken = "";
      this.refreshTokenExpiry = Date.now();
      localStorage.removeItem("languifyIsLoggedIn", false.toString());
      localStorage.removeItem("languifyRefreshTokenExpiry");
      localStorage.removeItem("languifyRefreshToken");
      localStorage.removeItem("guestId");
      sessionStorage.clear();
      this._unsetUser();
    } catch (error) {
      console.log(error);
    }
  }

  isLoggedIn() {
    return JSON.parse(localStorage.getItem("languifyIsLoggedIn"));
  }
}

export const Session = new SessionSingleton();

export default Session;