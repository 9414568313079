import React from "react";
import { makeStyles, styled } from "@mui/styles";
import Box from "@mui/material/Box";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import MicIcon from '@mui/icons-material/Mic';

import AppShell from "components/AppShell";

import SpeechRecorder from "languify-speech-recorder";

import NextSectionDialog from "components/dialogs/NextSectionDialog";
import useStore from "../store";
import shallow from "zustand/shallow";
import { useNavigateWithClient } from "hooks";
import { Progress } from "components/Head";
import { QuestionStates } from "components/constants";
import { askPermission, Session, subscribeToAudioLevel } from "utils";
import { Chip, LinearProgress } from "@mui/material";
import CircularProgressCountDown from "components/CircularProgressCountDown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    display: "flex",
    height: "100vh",
    backgroundColor: "#202124",
    position: "relative",
  },
  assessmentContainer: {
    margin: "auto",
    zIndex: 100,
    minHeight: 440,
    width: "90vw",
    minWidth: 300,
    maxWidth: 320,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0 7px rgba(0,0,0,0.25)",
    position: "relative",
    backgroundColor: "white", justifyContent: 'space-between'
  },
  button: {
    marginTop: 8,
    textTransform: "none"
  },
  active: {
    background: "#9FE2BF !important",
  },
  tag: {
    borderRadius: '4px',
    padding: 8,
    fontSize: 12,
    fontFamily: "Inter",
    margin: 0,
    background: "#D9D9D9",
    textTransform: "capitalize",
    transition: "all 500ms ease-in-out",
  },
  iconButton: {
    width: 'fit-content',
    padding: '12px',
    boxShadow: '0 0 7px rgba(0,0,0,0.25)',
    marginLeft: '16px',
    marginRight: '16px',
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: "3em",
    border: "none",
    transition: "all 300ms linear",
    "&:disabled": {
      boxShadow: '0 0 2px rgba(0,0,0,0.25)',
      cursor: "not-allowed",
      opacity: 0.7,
    },
    "&:hover:enabled": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:active:enabled": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "0 0 15px 0px rgba(0,0,0,0.35)",
    },
  },
  countdownContainer: {
    backgroundColor: '#fff',
    maxWidth: '350px',
    minWidth: '300px',
    height: '400px',
    overflow: 'hidden',
    borderRadius: '16px'
  },
  footer: {
    display: 'flex', justifyContent: 'center',
    padding: '10px 0px', borderTop: '2px solid #DADBDB', color: '#A8A8A8'
  }
}));

const HtmlTooltip = styled(({ className, title, ...props }) => (
  <MuiTooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title={
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "13px",
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    }
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#FFFFFF",
    color: 'black',
    maxWidth: 215,
    fontSize: 10,
    borderRadius: '4px',
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  }
}));

function Tutorial() {
  const classes = useStyles();
  const navigateWithClient = useNavigateWithClient();

  const count = useStore(state => state.count);
  const questionState = useStore(state => state.questionState);
  const setSectionDialog = useStore(state => state.setSectionDialog, shallow);
  const setQuestionState = useStore(state => state.setQuestionState, shallow);

  const [state, setState] = React.useState("LOADED");
  const [retake, setRetake] = React.useState(false);
  const [unMatched, setUnMatched] = React.useState(true);
  const [timeLeft, setTimeLeft] = React.useState(0);
  // const speechRecorder = React.useRef(null);
  const [audioLevel, setAudioLevel] = React.useState(0);
  const [matched, setMatched] = React.useState(false);
  const unsubscribeRef = React.useRef();
  const speechRecorderRef = React.useRef();
  const [startCountdown, setStartCountdown] = React.useState(false);

  const [tooltipShowed, setTooltipShowed] = React.useState({
    retake: false,
    submit: false,
    timer: false,
    answer: false,
  });

  const handleSubmit = () => {
    setState("SUBMITTED");
    speechRecorderRef.current.stop();
    setStartCountdown(true);
    if (unMatched && !matched) {
      setRetake(true);
      return;
    }

    setTimeout(() => setSectionDialog(), 500);
  };

  const handleBeginAssessment = () => {
    reset()
    setState("COMPLETED");
    setQuestionState(QuestionStates.WAITING);
    navigateWithClient("/assessment", { replace: true });
  }

  React.useEffect(() => {
    let toUpdate;
    switch (state) {
      case "RECORDING": {
        toUpdate = "answer";
        setTimeLeft(30);
        break;
      }
      case "RETAKE": {
        toUpdate = "retake";
        setTimeLeft(30);
        break;
      }
      case "SUBMITTED": {
        toUpdate = "submit";
        break;
      }
      default:
    }

    if (toUpdate) {
      setTooltipShowed((tooltipShowed) =>
        ({ ...tooltipShowed, [toUpdate]: true })
      );
    }
  }, [state]);

  React.useEffect(() => {
    if (!speechRecorderRef.current) {
      const isMobile = true;
      const serverUrl = process.env.REACT_APP_WEB_SOCKET_URL;
      const query = { token: Session.accessToken, application: 'none' };

      speechRecorderRef.current = new SpeechRecorder({
        isMobile, serverUrl, query, onInterimTranscript: (transcript) => {
          if (transcript.toLowerCase().search('lets go') !== -1) {
            setMatched(true);
            handleSubmit();
          }
        }
      });

      startRecording();
    }
  }, []);

  const startRecording = React.useCallback(async () => {
    if (speechRecorderRef.current) {
      setTimeLeft(30);
      setRetake(false);
      setState("RECORDING");
      await speechRecorderRef.current.start();
      console.log('Recording started.');
    }
  }, []);

  const subscribe = React.useCallback(async () => {
    try {
      unsubscribeRef.current = subscribeToAudioLevel((level) => setAudioLevel(level));
      console.log("subscribed.");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const reset = React.useCallback(async () => {
    if (unsubscribeRef.current && typeof unsubscribeRef.current === 'function') {
      unsubscribeRef.current();
      unsubscribeRef.current = null;
      console.log('unsubscribed.');
    }
    if (speechRecorderRef.current) {
      speechRecorderRef.current.stop();
      speechRecorderRef.current.destructor();
      speechRecorderRef.current = null;
      console.log('Recording stopped.');
    }
    setMatched(false);
  }, []);

  React.useEffect(() => {

    (async () => {
      const isPermitted = await askPermission({ audio: true, video: false });

      if (isPermitted) {
        setMatched(false);
        subscribe();
        setState("GRANTED");
      } else {
        setState("ASK_PERMISSION");
      }
    })();
  }, []);

  return (
    <AppShell>
      {!startCountdown ?
        <Box className={classes.root}>
          <Box className={classes.assessmentContainer}>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pt={2} pb={1} px={2}>
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  fontFamily="Inter"
                  fontStyle="normal"
                  color="#235598"
                >
                  Microphone Check
                </Typography>
                {/* <Chip
                  label={'Speaking'}
                  style={{
                    color: "#02569D",
                    backgroundColor: '#A6CEE3',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                /> */}
              </Box>
              <Progress questions={[1, 2, 3, 4, 5]} />
            </Box>
            <Box>
              <Box display='flex' justifyContent='center'>
                <Typography align="start" my={1} fontSize={18}>
                  Speak <br />
                  <b style={{ color: matched ? '#02BC9F' : '#02569D', fontSize: '28px' }}>
                    “Let’s Go”</b>
                  <br />
                  to start your assessment
                </Typography>
              </Box>
              <Box display='flex' alignItems={'center'} justifyContent='center' gap='10px' mt={3}>
                <MicIcon style={{ fontSize: '18px' }} />
                <Box sx={{ width: '180px' }}>
                  <LinearProgress
                    variant="determinate" value={audioLevel}
                    style={{ borderRadius: '10px' }}
                  />
                </Box>
              </Box>
              <Typography color='#237611' align="center" mt={2} fontSize={14}>
                Remember to sit in a noise free<br /> environment
              </Typography>
            </Box>
            <Box className={classes.footer}>
              <Typography>Be precise and clear</Typography>
            </Box>
          </Box>
        </Box>
        :
        <Box className={classes.countdownContainer}>
          <CircularProgressCountDown
            onDialogClose={() => setStartCountdown(false)}
            proceedToTest={() => handleBeginAssessment()}
          />
        </Box>
      }

      {/* <MicrophoneTestDialog open={retake} onRetake={handleRecordAnswer} /> */}

      <NextSectionDialog
        microphoneTest={true}
        open={questionState === QuestionStates.SECTION_DIALOG}
        onNext={handleBeginAssessment}
        count={count}
      />
    </AppShell>
  );
};

export default Tutorial;