import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

export class OTPAuth {
    app = null;
    auth = null;
    appVerifier = null;
    confirmationResult = null;

    constructor() {
        try {
            this.app = initializeApp(firebaseConfig);
            this.auth = getAuth(this.app);
        } catch (error) {
            console.log(error)
        }
    }

    setRecaptchaVerifier = (cb = () => { }) => {
        this.appVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("reCAPTCHA solved, allow signInWithPhoneNumber");
                cb();
            }
        }, this.auth);
    }

    sendOTP = async (phoneNumber) => {
        if (!this.appVerifier) throw new Error("DEBUG:OTPAuth: Doesn't provided recaptcha verifier.");
        this.confirmationResult =
            await signInWithPhoneNumber(this.auth, phoneNumber, this.appVerifier);
    }

    verifyOTP = async (otp) => {
        const result = await this.confirmationResult.confirm(otp);
        return result.user;
    }
}

export default OTPAuth;