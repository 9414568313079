import React from "react";
import { useNavigate, useParams } from "react-router";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import useNavigateWithClient from "hooks/useNavigateWithClient";
import { restoreSession, signOut, createGuestSession } from "services/auth";
import { subMinutes } from "utils";
import Session from "utils/Session";

function SessionHelper({ children }) {
  const { client } = useParams();
  const navigate = useNavigate();
  const navigateWithClient = useNavigateWithClient();

  const isResumeUser = !client;

  const [loading, setLoading] = React.useState(true);

  const isTokenExpiring = () => {
    console.log("DEBUG::isTokenExpiring");
    return subMinutes(new Date(Session.accessTokenExpiry), 5) <= new Date();
  };
  const isRefreshTokenExpiring = () => {
    return (
      subMinutes(new Date(Session.refreshTokenExpiry), 5).getTime() <=
      new Date().getTime()
    );
  };

  const goToHome = async () => {
    console.log("DEBUG::goToHome");
    await signOut();
    if (isResumeUser) navigate("/")
    else navigateWithClient("/");
  };

  async function onLoad() {
    setLoading(true);

    try {
      // if the refresh token is expiring then sign out and redirect to sign in
      const languifyRefreshTokenExpiry = localStorage.getItem(
        "languifyRefreshTokenExpiry"
      );

      if (languifyRefreshTokenExpiry) {
        const refreshTokenExpiryTime = new Date(
          parseInt(languifyRefreshTokenExpiry, 10)
        ).getTime();
        Session.refreshTokenExpiry =
          refreshTokenExpiryTime <= new Date().getTime()
            ? new Date().getTime()
            : refreshTokenExpiryTime;
      }

      if (isRefreshTokenExpiring()) {
        console.log("DEBUG::RefreshTokenExpired");
        if (isResumeUser) {
          console.log("DEBUG::Creating guest session");
          await createGuestSession();
        } else {
          if (Session.isLoggedIn()) await goToHome();
          return;
        }
      }

      console.log("DEBUG::isLoggedIn: ", Session.isLoggedIn());
      if (isTokenExpiring()) {
        console.log("DEBUG::AccessTokenExpired");
        Session.accessToken = "";
      }

      await restoreSession();

      setLoading(false);
    } catch (e) {
      console.log("DEBUG::onLoad error: ", e);
      goToHome();
    } finally {
      setLoading(false);
    }
  }

  async function setupRefresh() {
    try {
      if (isRefreshTokenExpiring()) {
        console.log("DEBUG::RefreshTokenExpired-2");
        await goToHome();
      }

      if (Session.isLoggedIn()) {
        if (isTokenExpiring()) Session.refreshToken = "";

        await restoreSession();
      }
    } catch (e) {
      console.error("setupTokenRefresh -> error refreshing token", e);
      await goToHome();
    }
  }

  React.useEffect(() => {
    onLoad()
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));

    const timerId = setInterval(setupRefresh, 3600000);

    return () => clearInterval(timerId);
  }, []);

  return loading ? (
    <Box
      width={"100vw"}
      height={"100vh"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress variant="indeterminate" />
    </Box>
  ) : (
    <>{children}</>
  );
}

export default SessionHelper;
