import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { QuestionTypes, Sections } from 'components/constants';
import useStore from '../../store';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
        width: "100%",
        marginTop: '10px'
    },
    content: {
        backgroundColor: "#CAD2DC",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        marginTop: 35,
        justifyContent: "flex-end",
        height: "fit-content",
        maxWidth: "340px",
    }, time: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        color: 'rgba(0, 0, 0, 0.6)',
    }
}));

function NextSectionDialog({
    open = false,
    onNext = () => { },
    type = QuestionTypes.spoken,
}) {
    const classes = useStyles();

    const timeLeft = useStore(state => state.timeLeft);
    const sectionNo = useStore(state => state.sectionNo);

    const minutes = Math.floor(timeLeft / 60);

    const seconds = timeLeft % 60;

    const questionInstructions = Sections[type]?.instructions;

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={(e) => { }}
            TransitionComponent={Transition}
        >
            <DialogTitle id="responsive-dialog-title" style={{
                borderBottom: '0.5px solid #D9D9D9',
                padding: '6px 20px'
            }}>
                <Box className={classes.time}>
                    Time remaining :&nbsp;
                    {minutes < 10 ? "0" : ""}{Math.floor(timeLeft / 60)}
                    :
                    {seconds < 10 ? "0" : ""}{seconds} min
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography
                    style={{
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '16px',
                    }}
                    my={1}
                >Section {sectionNo+1} : {Sections[type]?.label}</Typography>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(35, 85, 152, 0.16)',
                    maxWidth: 350,
                    borderRadius: '6px',
                }}
                    p={2}
                >
                    <ul style={{
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                        color: '#235598',
                        lineHeight: '18px',
                        margin: 0,
                        paddingInlineStart: 16,
                    }}>
                        {
                            questionInstructions?.map((questionInstruction, i) => (
                                <li key={i} >{questionInstruction}</li>
                            ))
                        }
                    </ul>
                    <Button
                        variant="contained"
                        onClick={onNext}
                        className={classes.button}
                        endIcon={<ChevronRightIcon />}
                    >
                        Next
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default NextSectionDialog;